import Vue from 'vue'

class AnomalyRepository {
  subscription() {
    return new Promise((resolve, reject) => {

      Vue.auth.fetch({
        url: '/api/anomaly/subscription',
        method: 'post',
      }).then(
          response => resolve(response.data)
      ).catch(
          err => reject(err)
      )
    })
  }

}

export default new AnomalyRepository()
