var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"anomaly-subscription pa-10"},[_c('title-page',{attrs:{"icon":"mdi-alert-circle-outline"}},[_vm._v(" "+_vm._s(_vm.$t('anomaly.subscription-anomalies'))+" ")]),_c('v-expansion-panels',{attrs:{"inset":""}},[(_vm.anomalies.organizationsWithoutSubscription.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Organisations sans abonnement ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.organizationsWithoutSubscription.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
              ],"items":_vm.anomalies.organizationsWithoutSubscription},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,false,1329845028)})],1)],1)]:_vm._e(),(_vm.anomalies.chargingPointsWithoutLicence.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Bornes sans licence ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.chargingPointsWithoutLicence.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
              ],"items":_vm.anomalies.chargingPointsWithoutLicence},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'charging-points.detail.licences', params: {id: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,2041344206)})],1)],1)]:_vm._e(),(_vm.anomalies.chargingPointsWithoutMonetizationLicence.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Bornes sans licence de monetisation ayant des charges payantes ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.chargingPointsWithoutMonetizationLicence.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
              ],"items":_vm.anomalies.chargingPointsWithoutMonetizationLicence},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'charging-points.detail.licences', params: {id: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,2041344206)})],1)],1)]:_vm._e(),(_vm.anomalies.meterDevicesWithoutLicence.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Compteur sans licence ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.meterDevicesWithoutLicence.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
              ],"items":_vm.anomalies.meterDevicesWithoutLicence},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'meter-device.detail', params: {id: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,2819842859)})],1)],1)]:_vm._e(),(_vm.anomalies.homeChargingPointsWithoutEnergyPrice.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Bornes sans Tarification au kWh ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.homeChargingPointsWithoutEnergyPrice.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
              ],"items":_vm.anomalies.homeChargingPointsWithoutEnergyPrice},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'charging-points.detail', params: {id: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization.id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,1843404871)})],1)],1)]:_vm._e(),(_vm.anomalies.homeMeterDevicesWithoutEnergyPrice.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Compteur sans Tarification au kWh ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.homeMeterDevicesWithoutEnergyPrice.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
              ],"items":_vm.anomalies.homeMeterDevicesWithoutEnergyPrice},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'meter-device.detail', params: {id: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization.id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,1181819956)})],1)],1)]:_vm._e(),(_vm.anomalies.homeChargingPointsEnergyPriceTooBig.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Bornes avec Tarification au kWh excessive ( > 0,5 €) ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.homeChargingPointsEnergyPriceTooBig.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
                  {text: _vm.$t('charging-point.energy-provider-kwh-price'), value: 'energyProviderKwhPrice', align: 'left'},
              ],"items":_vm.anomalies.homeChargingPointsEnergyPriceTooBig},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'charging-points.detail', params: {id: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.energyProviderKwhPrice",fn:function({value}){return [_vm._v(" "+_vm._s(value)+" € ")]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization.id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,1448550937)})],1)],1)]:_vm._e(),(_vm.anomalies.homeMeterDevicesEnergyPriceTooBig.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Compteur avec Tarification au kWh excessive ( > 0,5 €) ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.homeMeterDevicesEnergyPriceTooBig.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
                  {text: _vm.$t('charging-point.energy-provider-kwh-price'), value: 'energyProviderKwhPrice', align: 'left'},
              ],"items":_vm.anomalies.homeMeterDevicesEnergyPriceTooBig},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'charging-points.detail', params: {id: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.energyProviderKwhPrice",fn:function({value}){return [_vm._v(" "+_vm._s(value)+" € ")]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization.id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,1448550937)})],1)],1)]:_vm._e(),(_vm.anomalies.organizationWithHomeChargingPointsWithoutExpenseSubscription.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Organisations (avec borne à domicile) sans abonnement de remboursement ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.organizationWithHomeChargingPointsWithoutExpenseSubscription.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
              ],"items":_vm.anomalies.organizationWithHomeChargingPointsWithoutExpenseSubscription},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,false,1329845028)})],1)],1)]:_vm._e(),(_vm.anomalies.homeChargingPointsWithoutExpenseSubscriptionLicence.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Bornes à domicile sans licence de remboursement ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.homeChargingPointsWithoutExpenseSubscriptionLicence.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
              ],"items":_vm.anomalies.homeChargingPointsWithoutExpenseSubscriptionLicence},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'charging-points.detail.licences', params: {id: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization.id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,3524709521)})],1)],1)]:_vm._e(),(_vm.anomalies.homeMeterDevicesWithoutExpenseSubscriptionLicence.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Compteur à domicile sans licence de remboursement ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.homeMeterDevicesWithoutExpenseSubscriptionLicence.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
              ],"items":_vm.anomalies.homeMeterDevicesWithoutExpenseSubscriptionLicence},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'meter-device.detail', params: {id: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization.id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,1181819956)})],1)],1)]:_vm._e(),(_vm.anomalies.expenseSubscriptionWithoutTariffType.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Abonnements de remboursement sans type de Tarification ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.expenseSubscriptionWithoutTariffType.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
              ],"items":_vm.anomalies.expenseSubscriptionWithoutTariffType},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions.edit', params: {id: item.organization._id, subscriptionId: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,2664859847)})],1)],1)]:_vm._e(),(_vm.anomalies.expenseSubscriptionOrganizationWithoutPrice.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Abonnements de remboursement sans Tarification au kWh ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.expenseSubscriptionOrganizationWithoutPrice.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
              ],"items":_vm.anomalies.expenseSubscriptionOrganizationWithoutPrice},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions.edit', params: {id: item.organization._id, subscriptionId: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,2664859847)})],1)],1)]:_vm._e(),(_vm.anomalies.expenseSubscriptionOrganizationEnergyPriceTooBig.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Abonnements de remboursement avec Tarification Excessive au kWh ( > 0,5 €) ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.expenseSubscriptionOrganizationEnergyPriceTooBig.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
              ],"items":_vm.anomalies.expenseSubscriptionOrganizationEnergyPriceTooBig},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions.edit', params: {id: item.organization._id, subscriptionId: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,2664859847)})],1)],1)]:_vm._e(),(_vm.anomalies.memberWithNoCorporateToken.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Membres sans badge pro ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.memberWithNoCorporateToken.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
              ],"items":_vm.anomalies.memberWithNoCorporateToken},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'member.tokens', params: {id: item.organization.id, memberId: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.customerLabel(item))+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization.id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,3136022561)})],1)],1)]:_vm._e(),(_vm.anomalies.expenseMemberWithoutIban.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Membres sans IBAN ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.expenseMemberWithoutIban.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
              ],"items":_vm.anomalies.expenseMemberWithoutIban},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'member.edit', params: {id: item.organization.id, memberId: item._id}},"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.customerLabel(item))+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization.id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,1829851221)})],1)],1)]:_vm._e(),(_vm.anomalies.expenseMemberWithHighAmount.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Membres ayant un montant de remboursement excessif ( > 400 €) ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.expenseMemberWithHighAmount.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
                  {text: _vm.$t('amount'), value: 'amount', align: 'left'},
              ],"items":_vm.anomalies.expenseMemberWithHighAmount},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.expense-periods.member', params: {id: item.organization.id, memberId: item._id, periodId: item.expensePeriodId}},"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.customerLabel(item))+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization.id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}},{key:"item.amount",fn:function({item}){return [_vm._v(" "+_vm._s(item.amount.toFixed(2))+" € ")]}}],null,false,1032507299)})],1)],1)]:_vm._e(),(_vm.anomalies.expenseMemberWithNoAmount.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Membres ayant des charges et un montant de remboursement à 0 € ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.expenseMemberWithNoAmount.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
                  {text: _vm.$t('amount'), value: 'amount', align: 'left'},
              ],"items":_vm.anomalies.expenseMemberWithNoAmount},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.expense-periods.member', params: {id: item.organization.id, memberId: item._id, periodId: item.expensePeriodId}},"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.customerLabel(item))+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization.id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}},{key:"item.amount",fn:function({item}){return [_vm._v(" "+_vm._s(item.amount.toFixed(2))+" € ")]}}],null,false,1032507299)})],1)],1)]:_vm._e(),(_vm.anomalies.expenseMemberTooManyCdr.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Membres ayant trop de ligne de remboursement ( > 100 lignes) ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.expenseMemberTooManyCdr.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
                  {text: _vm.$t('nb-lines'), value: 'count', align: 'left'},
              ],"items":_vm.anomalies.expenseMemberTooManyCdr},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.expense-periods.member', params: {id: item.organization.id, memberId: item._id, periodId: item.expensePeriodId}},"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.customerLabel(item))+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.subscriptions', params: {id: item.organization.id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,3693354617)})],1)],1)]:_vm._e(),(_vm.anomalies.expenseLineWithHighEnergy.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Membre avec consommation élevée de kWh à domicile (charge > 100 kWh) ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.expenseLineWithHighEnergy.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},
                  {text: _vm.$t('kWh'), value: 'energy'},

              ],"items":_vm.anomalies.expenseLineWithHighEnergy},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.expense-periods.member', params: {id: item.organization.id, memberId: item._id, periodId: item.expensePeriodId}},"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.customerLabel(item))+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.detail', params: {id: item.organization.id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}},{key:"item.energy",fn:function({item}){return [_vm._v(" "+_vm._s((item.energy/1000).toFixed(3))+" kWh ")]}}],null,false,2819209300)})],1)],1)]:_vm._e(),(_vm.anomalies.expenseMemberWithoutExpenseLine.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Membres sans ligne de remboursement ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.expenseMemberWithoutExpenseLine.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$t('organization.organization'), value: 'organization.name', align: 'left'},

              ],"items":_vm.anomalies.expenseMemberWithoutExpenseLine},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.expense-periods.manage', params: {id: item.organization.id, periodId: item.expensePeriodId}},"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.customerLabel(item))+" ")])]}},{key:"item.organization.name",fn:function({item}){return [_c('router-link',{attrs:{"to":{name: 'organizations.detail', params: {id: item.organization.id}},"target":"_blank"}},[_vm._v(" "+_vm._s(item.organization.name)+" ")])]}}],null,false,1679772107)})],1)],1)]:_vm._e(),(_vm.anomalies.corporateTokenWithWrongLink.length > 0)?[_c('v-expansion-panel',{attrs:{"cols":"12","md":"6"}},[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text"},[_vm._v(" Badges entreprise avec lien invalide ")]),_c('v-col',{staticClass:"text-right pr-6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.anomalies.corporateTokenWithWrongLink.length)+" ")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":[
                  {text: _vm.$t('Badge Uid'), value: 'uid'},
                  {text: _vm.$t('name'), value: 'name'},
                  {text: _vm.$tc('customer.customer', 1), value: 'customer.lastname', align: 'left'},

              ],"items":_vm.anomalies.corporateTokenWithWrongLink},scopedSlots:_vm._u([{key:"item.uid",fn:function({item}){return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openRfidModal(item)}}},[_vm._v(_vm._s(item.uid))])]}},{key:"item.customer.lastname",fn:function({item}){return [(item.customer)?[_c('router-link',{attrs:{"to":{name: 'customer.detail', params: {id: item.customer.id}},"target":"_blank"}},[(item.customer?.lastname)?[_vm._v(" "+_vm._s(item.customer.firstname)+" "+_vm._s(item.customer.lastname)+" ")]:[_vm._v(" "+_vm._s(item.customer?.evcoId)+" ")]],2)]:[_vm._v(" Pas de lien avec un client ")]]}}],null,false,1337731743)})],1)],1)]:_vm._e()],2),_c('rfid-form-modal',{ref:"rfidFormModal",on:{"change":_vm.loadData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }